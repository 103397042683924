dmx.Component('google-maps-marker', {

    attributes: {
        latitude: {
            type: Number,
            default: null
        },

        longitude: {
            type: Number,
            default: null
        },

        address: {
            type: String,
            default: null
        },

        label: {
            type: String,
            default: null
        },

        'label-color': {
            type: String,
            default: null
        },

        title: {
            type: String,
            default: null
        },

        info: {
            type: String,
            default: null
        },

        type: {
            type: String,
            default: null
        },

        image: {
            type: String,
            default: null
        },

        animation: {
            type: String,
            default: null
        },

        draggable: {
            type: Boolean,
            default: false
        }
    },

    update: function(props) {
        if (this.props.latitude != props.latitude || this.props.longitude != props.longitude) {
            this.marker.setPosition({ lat: +this.props.latitude, lng: +this.props.longitude });
            this.marker.setVisible(true);
        }

        if (this.props.address != props.address) {
            if (this.parent.geocodeCache[this.props.address]) {
                this.marker.setPosition(this.parent.geocodeCache[this.props.address]);
            } else {
                this.parent.geocoder.geocode({ address: this.props.address }, function(results, status) {
                    if (status == 'OK') {
                        this.parent.geocodeCache[this.props.address] = results[0].geometry.location;
                        this.marker.setPosition(this.parent.geocodeCache[this.props.address]);
                        this.marker.setVisible(true);
                        localStorage.geocodeCache = JSON.stringify(this.parent.geocodeCache);
                    } else {
                        console.warn('Geocode was not successful for the following reason: ' + status)
                    }
                }.bind(this));
            }
        }

        if (this.props.label != props.label || this.props['label-color'] != props['label-color']) {
            this.marker.setLabel({
                color: this.props['label-color'],
                text: this.props.label
            });
        }

        if (this.props.title != props.title) {
            this.marker.setTitle(this.props.title);
        }

        if (this.props.info != props.info) {
            this.marker.infoWindow = new google.maps.infoWindow({
                content: this.props.info
            });
        }

        if (this.props.type != props.type) {
            this.marker.setIcon(this.parent.markerTypes[this.props.type]);
        }

        if (this.props.image != props.image) {
            this.marker.setIcon(this.props.image);
        }

        if (this.props.animation != props.animation) {
            this.marker.setAnimation(this.parent.getMarkerAnimation(this.props.animation));
        }

        if (this.props.draggable != props.draggable) {
            this.marker.setDraggable(this.props.draggable);
        }
    }

});
